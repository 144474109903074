import 'react-table/react-table.css';

import { EditIcon, Label, LargeSectionTitle, LegalCopy, LoadingSpinnerIcon, TrashIcon } from '@animoto/components';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactTable from 'react-table';

import AnimotoLayoutSentryWrapped from '../components/AnimotoLayout';
import {
  iconButton,
  loadingIcon,
  loadingWrap,
  redirectsBody,
  redirectsTitle,
  rowToContent,
  wrap
} from './redirects.module.css';

export default class RedirectsPage extends Component {
  constructor(props) {
    super(props);
    const { data: { contentfulRedirects } } = this.props;
    this.state = {
      data          : contentfulRedirects,
      didChange     : false,
      previousValue : '',
      isLoading     : false
    };
    this.renderEditable = this.renderEditable.bind(this);
    this.renderNonEditable = this.renderNonEditable.bind(this);
    this.renderDelete = this.renderDelete.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.onChangeEditable = this.onChangeEditable.bind(this);
    this.setInputRef = this.setInputRef.bind(this);

    this.focusTextInput = this.focusTextInput.bind(this);
    this.onBlurInput = this.onBlurInput.bind(this);
    const { data: { redirects } } = this.state;
    this.inputRefs = redirects.reduce((acc, val, i) => {
      acc[i] = '';
      return acc;
    }, {});
  }

  handleDelete(index) {
    const { data: { redirects } } = this.state;
    if (window.confirm('Are you sure you want to delete this item?')) { // eslint-disable-line no-alert
      this.setState({ isLoading : true });
      fetch('/redirects/update', {
        method  : 'POST',
        headers : {
          'Content-Type' : 'application/json'
        },
        body : JSON.stringify({
          isDelete : true,
          from     : redirects[index].from
        })
      })
        .then((res) => {
          this.setState({ isLoading : false });
          if (res.status === 200) {
            alert('Delete successful!'); // eslint-disable-line no-alert
          } else {
            alert('Delete unsuccessful!'); // eslint-disable-line no-alert
          }
        })
        .catch((err) => {
          this.setState({ isLoading : false });
          alert(`Error: ${err}`); // eslint-disable-line no-alert
        });
      this.setState((prevState) => ({
        data : {
          redirects : prevState.data.redirects.filter((value, internalIndex) => (
            internalIndex !== index
          ))
        }
      }));
      this.setState({ didChange : false });
    }
  }

  onChangeEditable(event, index, id) {
    const { data: { redirects } } = this.state;
    const { target: { value } } = event;
    if (redirects[index][id] !== value) {
      const redirectsCopy = [...redirects];
      redirectsCopy[index][id] = value;
      this.setState({
        data : {
          redirects : redirectsCopy
        },
        didChange : true
      });
    }
  }

  onBlurInput(index) {
    const { didChange, previousValue, data : { redirects } } = this.state;
    const inputRef = this.inputRefs[index];
    if (didChange) {
      const foundChain = redirects.find((existingRedirects) => existingRedirects.from === redirects[index].to);
      const startsWithSlash = redirects[index].to.startsWith('/');
      const endsWithSlash = redirects[index].to.endsWith('/');
      let revert = true;
      if (foundChain) {
        alert('There is already a redirect with this value!'); // eslint-disable-line no-alert
      } else if (!startsWithSlash) {
        alert('Please start your edit with a "/".'); // eslint-disable-line no-alert
      } else if (endsWithSlash) {
        alert('Please do not include a "/" at the end.'); // eslint-disable-line no-alert
      } else if (window.confirm('Are you sure about this edit?')) { // eslint-disable-line no-alert
        revert = false;
        this.setState({ isLoading : true });
        fetch('/redirects/update', {
          method  : 'POST',
          headers : {
            'Content-Type' : 'application/json'
          },
          body : JSON.stringify({
            isDelete     : false,
            changedValue : redirects[index].to,
            from         : redirects[index].from
          })
        })
          .then((res) => {
            this.setState({ isLoading : false });
            if (res.status === 200) {
              alert('Edit successful!'); // eslint-disable-line no-alert
            } else {
              alert('Edit unsuccessful!'); // eslint-disable-line no-alert
            }
          })
          .catch((err) => {
            this.setState({ isLoading : false });
            alert(`Error: ${err}`); // eslint-disable-line no-alert
          });
      }
      if (revert) {
        const redirectsCopy = [...redirects];
        redirectsCopy[index].to = previousValue;
        this.setState({
          data : {
            redirects : redirectsCopy
          }
        });
      }
    }
    inputRef.readOnly = true;
    this.setState({ didChange : false });
  }

  setInputRef(event, index) {
    this.inputRefs[index] = event;
  }

  focusTextInput(index) {
    const inputRef = this.inputRefs[index];
    this.setState({ previousValue : inputRef.value });
    inputRef.readOnly = false;
    inputRef.focus();
    inputRef.select();
  }

  renderDelete(cellInfo) {
    const { data: { redirects } } = this.state;
    const { index, column : { id } } = cellInfo;
    return (
      <LegalCopy>{redirects[index][id]}
        <button
          className={iconButton}
          onClick={() => this.handleDelete(index)}
          type="button"
        >
          <TrashIcon size="medium" />
        </button>
        <button
          className={iconButton}
          onClick={() => this.focusTextInput(index)}
          type="button"
        >
          <EditIcon size="medium" />
        </button>
      </LegalCopy>
    );
  }

  renderNonEditable(cellInfo) {
    const { data: { redirects } } = this.state;
    const { index, column : { id } } = cellInfo;
    return (
      <div>{redirects[index][id]}</div>
    );
  }

  renderEditable(cellInfo) {
    const { data: { redirects } } = this.state;
    const { index, column : { id } } = cellInfo;
    return (
      <input
        ref={(event) => {
          this.setInputRef(event, index);
        }}
        className={rowToContent}
        onBlur={() => this.onBlurInput(index)}
        onChange={(event) => {
          this.onChangeEditable(event, index, id);
        }}
        readOnly
        spellCheck="false"
        value={redirects[index][id]}
      />
    );
  }

  render() {
    const { data: { redirects }, isLoading } = this.state;
    return (
      <AnimotoLayoutSentryWrapped pageTitle="Redirects">
        <div className={wrap}>
          <LargeSectionTitle className={redirectsTitle}>
            Redirects Page
          </LargeSectionTitle>
          {isLoading && (
            <>
              <div className={loadingWrap} />
              <LoadingSpinnerIcon className={loadingIcon} size="large" />
            </>
          )}
          <div className={redirectsBody}>
            <ReactTable
              className="-striped -highlight"
              columns={[
                {
                  Header   : () => <Label>From </Label>,
                  accessor : 'from',
                  style    : { whiteSpace : 'unset' },
                  show     : true,
                  Cell     : this.renderNonEditable
                },
                {
                  Header   : () => <Label>To</Label>,
                  accessor : 'to',
                  show     : true,
                  Cell     : this.renderEditable
                },
                {
                  Header   : () => <Label>Redirected On</Label>,
                  accessor : 'redirectedOn',
                  show     : true,
                  Cell     : this.renderDelete,
                  Filter   : () => <div />
                }
              ]}
              data={redirects}
              defaultFilterMethod={(filter, row) => String(row[filter.id]).includes(filter.value)}
              defaultPageSize={10}
              filterable
              minRows={0}
            />
          </div>
        </div>
      </AnimotoLayoutSentryWrapped>
    );
  }
}

export const pageQuery = graphql`
  query {
    contentfulRedirects {
      redirects {
        from
        to
        redirectedOn
      }
    }
  }
`;

RedirectsPage.propTypes = {
  data : PropTypes.shape({
    contentfulRedirects : PropTypes.shape({
      redirects : PropTypes.arrayOf(PropTypes.shape({
        from         : PropTypes.string,
        redirectedOn : PropTypes.string,
        to           : PropTypes.string
      }))
    })
  }).isRequired
};
